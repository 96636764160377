'use client';
import { LoginUserForm } from '@/components/LoginUserForm';
import { useRouter } from 'next/navigation';

const login = () => {
  const router = useRouter();

  return (
    <>
      <LoginUserForm onDone={() => router.push('/')} />
    </>
  );
};

export default login;
