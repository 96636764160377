import QueryStringAddon from 'wretch/addons/queryString';
import wretch from 'wretch';

const BASE_URL = process.env.NEXT_PUBLIC_API_URL;

export const httpClient = wretch(BASE_URL)
  .options({
    credentials: 'include',
    mode: 'cors',
  })
  .headers({
    'x-user-type': 'backoffice',
  })
  .addon(QueryStringAddon);
