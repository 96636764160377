import { WretchError } from 'wretch/resolver';
import { useToast } from 'ui';
import { useTranslations } from 'i11n';

export const useApiError = () => {
  const { toast } = useToast();

  const t = useTranslations();

  const handleError = (error: WretchError) => {
    const code = error?.json?.code || 'unexpected_error';

    console.log('code', code);

    toast({
      title: t(`errors.${code}.title`),
      description: t(`errors.${code}.description`),
    });
  };

  return {
    handleError,
  };
};
