'use client';
import { loginUser } from '@/lib/loginUser';
import { useRouter } from 'next/router';
import { requiredFieldMessage } from 'piramid-constants';
import { useCallback, useState } from 'react';
import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
} from 'ui';
import Link from 'next/link';
import { useApiError } from '@/hooks/useApiError';
import { LoginBackofficeRequestBody, loginBackofficeRequestBody } from 'bff';

export const LoginUserForm = ({ onDone }: { onDone: () => void }) => {
  const [isLoading, setIsLoading] = useState(false);

  const { handleError } = useApiError();

  const handleSubmit = useCallback((data: LoginBackofficeRequestBody) => {
    setIsLoading(true);

    loginUser(data)
      .then((res) => {
        if (onDone) {
          onDone();
        }
      })
      .catch(handleError)
      .finally(() => setIsLoading(false));
  }, []);

  const form = useForm<LoginBackofficeRequestBody>({
    resolver: zodResolver(loginBackofficeRequestBody),
  });

  return (
    <>
      <div className='w-full mt-20 mb-auto'>
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(handleSubmit)}
            className='w-full grid grid-cols-1 gap-4'
          >
            <FormField
              control={form.control}
              name='username'
              defaultValue=''
              render={({ field }) => {
                return (
                  <FormItem>
                    <FormLabel>Usuario</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                );
              }}
            />

            <FormField
              control={form.control}
              name='password'
              defaultValue=''
              render={({ field }) => {
                return (
                  <FormItem>
                    <FormLabel>Contraseña</FormLabel>
                    <FormControl>
                      <Input type='password' {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                );
              }}
            />

            <Button loading={isLoading} type='submit' className='mt-5'>
              Iniciar sesión
            </Button>
          </form>
        </Form>
      </div>
    </>
  );
};
